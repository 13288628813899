// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import '@angular/material/prebuilt-themes/indigo-pink.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primeauthortool-primary: mat.define-palette(mat.$indigo-palette);
$primeauthortool-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$primeauthortool-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$primeauthortool-theme: mat.define-light-theme($primeauthortool-primary, $primeauthortool-accent, $primeauthortool-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($primeauthortool-theme);
html,
body {
    height: 100%;
}

body {
    margin: 0;
}

//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
//GLOBAL 
.text-center {
    text-align: center!important;
}

li {
    list-style-type: none;
}

ul,
h5,
p {
    padding: 0;
    margin: 0;
    font-weight: normal;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.bold {
    font-weight: bold !important;
}

.displayNone {
    display: none;
}

.statusG {
    color: #187C2E;
}

.statusF {
    color: #CE0B0B;
}

.closeSurveyStep {
    color: #0089D0;
    font-family: Ubuntu-Regular;
    font-size: 13px;
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}

.mat-form-field,
input {
    font-family: Ubuntu-Regular;
}

.required:after {
    content: " *";
    color: #CE0B0B;
}

.mat-error {
    font-family: Ubuntu-Regular;
    font-size: 11px;
}

.exp-toggle {
    color: #0089D0 !important;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.testDate {
    position: relative;
    width: 17%;
}

.testDate.testDateAlign {
    margin:6px 0 0 20px !important;
}

.testDate label {
    float: left;
    width: auto;
    margin-top: 6px;
    text-align: left;
    margin-right: 10px;
}

.testDate input {
    width: 53%;
    font-size: 13px !important;
    font-family: Ubuntu-Regular;
    padding-left: 5px !important;
    float: left;
    text-align: left;
}

.testDate mat-datepicker-toggle {
    float: left;
    position: absolute;
    right: 13%;
    bottom: 13px;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.mat-form-field-infix {
    border-top: 0px solid transparent;
}

.is-active {
    background: #027AB9;
    outline: none;
}

.mat-datepicker-toggle {
    width: 17px;
    float: right;
    height: 22px;
}

.mat-datepicker-toggle-default-icon {
    height: 19px !important;
    color: #000000;
}

.mat-select-panel-wrap .mat-select-panel {
    margin-left: 10px;
    margin-top: 35px;
    max-height: 265px;
}

input[readonly="true"], textarea[readonly="true"] {
    cursor: not-allowed;
    opacity: 0.5;
}

button[disabled="true"], button[disabled="true"]:hover {
    cursor: not-allowed;
}

.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
    caret-color: #000;
}
textarea { 
    resize: none; 
}

.mat-slide-toggle-bar {
    margin-right: 2px !important;
}

.paddingBN {
    padding-bottom: 0 !important;
}

.paddingTN {
    padding-top: 0 !important;
}

.paddingLN {
    padding-left: 0 !important;
}

.paddingRN {
    padding-right: 0 !important;
}

.paddingN {
    padding: 0 !important;
}

.marginTN {
    margin-top: 0 !important;
}

.marginLN {
    margin-left: 0 !important;
}

.marginRN {
    margin-right: 0 !important;
}

.marginBN {
    margin-bottom: 0 !important;
}

.marginR10 {
    margin-right: 10px !important;
}

.marginT5 {
    margin-top: 5px !important;
}

.marginT10 {
    margin-top: 10px !important;
}

.marginT15 {
    margin-top: 15px !important;
}

.marginT20 {
    margin-top: 20px !important;
}

.searchBar input::placeholder {
    color:#999 !important;
}

.makeCopyText {
    color: #000 !important;
    width: 38%;
    float: left;
    line-height: 10px;
    display: block;
    clear: both;
    margin: 25px 0 0 5px;
    cursor: pointer;
}

.editText {
    color: #000 !important;
    width: 38%;
    float: left;
    line-height: 10px;
    margin: 25px 0 0 5px;
    border-left: 1px solid #ccc;
    padding-left: 5px;
}

.ClearAllWtext {
    height: 1px;
    margin-top: -4px !important;
    float: left;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #0089D0 !important;
}

.clearFilter {
    -webkit-appearance: none;
    border: 0 none !important;
    font-family: Ubuntu-Regular;
    font-size: 13px !important;
    padding-right: 5px !important;
    padding-left: 0 !important;
    color: #0089D0 !important;
}

input::-webkit-input-placeholder {
    color: transparent;
}

input:focus::-webkit-input-placeholder {
    color: #888;
}

.align-autocomplete .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-selection-list .mat-list-item .mat-list-text {
    font-family: Ubuntu-Regular;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
}

// .align-autocomplete {
//     margin-top:0 !important;
// }
.table-filter-section .mat-header-cell app-daterange-picker-component .mat-form-field .mat-form-field-infix input.mat-input-element {
    cursor: default;
    margin: 0 !important;
    width: 100% !important;
}

.table-filter-section .mat-header-cell app-daterange-picker-component .mat-form-field .mat-form-field-infix {
    margin: 0 !important;
}

.table-filter-section .mat-header-cell app-daterange-picker-component .mat-form-field .mat-form-field-flex {
    padding-left: 5px;
}

.hastag {
    margin-bottom: 10px;
    float: left;
}

.hastag .mat-list-item-content {
    font-family: Ubuntu-Regular;
    font-size: 13px;
}

.hastag .mat-icon-button {
    width: 10px !important;
    height: 10px !important;
}

.hastag>span {
    float: left;
    margin-right: 10px;
}

.hastag>span:last-child {
    margin-left: 0;
}

.hastag+mat-selection-list {
    clear: both;
}

.mat-expansion-indicator::after {
    color: #0089D0 !important;
}

.hastagDelete {
    height: 11px !important;
    width: 12px !important;
    background: #0089D0;
    border-radius: 20px;
    color: #ffffff;
    font-size: 9px;
    padding: 1px;
    font-family: Ubuntu-Regular;
    line-height: 10px !important;
    margin-top: -1px;
}

.clear {
    clear: both !important;
}

.sidebar .sidebar-sticky li.nav-item.inputsheetNav:hover ul.inputMultiMenu {
    display: block;
}

.inputMultiMenu {
    position: fixed;
    height: 100px;
    float: left;
    width: auto;
    left: 7.3% !important;
    color: #fff;
    font-size: 15px;
    font-family: Ubuntu-Regular;
    z-index: 9999;
    top: 64px !important;
    display: none;
    padding-left: 5px;
}

.inputMultiMenu li {
    height: 15px !important;
    cursor: pointer;
    text-align: left !important;
    display: block !important;
    padding: 9px 15px 9px 10px !important;
    font-size: 13px;
    font-family: Ubuntu-Regular;
    background: #0089D0;
}

.inputMultiMenu li:hover {
    background: #027AB9;
    outline: 0 none;
}

.profileInfo {
    cursor: pointer;
    margin: 10px 10px 0 0;
}

.profileInfo i {
    margin: 4px 0 0 10px;
}

.mat-menu-panel .mat-menu-content {
    padding-top: 15px !important;
    padding-bottom: 0px !important;
}

.templateHeader .mat-form-field-underline {
    display: none;
}

.templateHeader .mat-form-field-label-wrapper,
.InputSheetHeader .mat-form-field-label-wrapper {
    padding-top: 22px;
    font-size: 14px;
    font-family: Ubuntu-Regular;
    color: #000 !important;
}

.templateHeader .createSection button.copy {
    width: 60px !important;
    min-width: 50px;
}

.templateHeader .mat-form-field .mat-form-field-infix mat-select.mat-select,
.InputSheetHeader .mat-form-field .mat-form-field-infix mat-select.mat-select {
    margin-top: 2px;
    width: 95% !important;
    padding-left: 5px !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px !important;
    line-height: 15px;
    height: 16px;
    color: #000000;
}

.InputSheetHeader .mat-form-field .mat-form-field-infix,
.InputSheetHeader .mat-form-field .mat-form-field-flex {
    padding: 0 !important;
}

.InputSheetHeader .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.templateHeader .mat-form-field,
.InputSheetHeader .mat-form-field {
    width: 20%;
    padding: 0 !important;
    border: 0 none !important;
    margin-top: -4px;
    margin-left: 5% !important;
    float: left;
}

.templateHeader .mat-form-field {
    width: 32%;
}

.InputSheetHeader .mat-form-field {
    margin-top: -6px !important;
}

.templateHeader .mat-form-field .mat-form-field-infix,
.InputSheetHeader .mat-form-field .mat-form-field-infix {
    width: 100%;
    height: 15px;
    margin-left: 0;
    padding-left: 0;
}

.templateHeader .mat-form-field .mat-form-field-wrapper .mat-form-field-outline-thick,
.InputSheetHeader .mat-form-field .mat-form-field-wrapper .mat-form-field-outline-thick {
    background: #EEEEEE;
    border: 1px solid #d0d0d0;
    color: transparent;
    border-radius: 5px;
}

.templateHeader .mat-form-field .mat-form-field-infix .mat-select-trigger {
    width: 100%;
}

.templateHeader .mat-form-field .mat-form-field-infix .mat-select-trigger .mat-select-value {
    color: #000000;
    font-size: 13px;
    font-family: "Ubuntu-Regular";
    line-height: 15px;
}

.templateHeader mat-select.mat-select,
.InputSheetHeader mat-select.mat-select {
    margin-top: 7px;
    width: 94.5% !important;
    padding-left: 5px !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px !important;
    line-height: 15px;
    height: 18px;
    border: 1px solid #D0D0D0;
}

.templateHeader input.mat-input-element:hover,
.templateHeader mat-select.mat-select:hover,
.templateHeader textarea:hover,
.InputSheetHeader input.mat-input-element:hover,
.InputSheetHeader mat-select.mat-select:hover,
.InputSheetHeader textarea:hover {
    border: 1px solid #D0D0D0;
    // background:#EEEEEE;
}

.templateHeader input.mat-input-element:active,
.templateHeader mat-select.mat-select:active,
.templateHeader textarea:active,
.InputSheetHeader input.mat-input-element:active,
.InputSheetHeader mat-select.mat-select:active,
.InputSheetHeader textarea:active {
    border: 1px solid #5F5858;
    // background:#EEEEEE;
}

.templateHeader .mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    top: 29px !important;
}

.templateHeader .templateRepository .bottomArrow {
    top: 20px;
    right: 0px;
}

.InputSheetHeader .mat-form-field .bottomArrow {
    top: 14px;
}

.templateHeader .templateDescription {
    margin-top: 8px;
    margin-left: 20px !important;
}

.templateHeader .templateDescription input.sharedListName {
    width: 65% !important;
    font-size: 13px !important;
}

.templateHeader .templateDescription .mat-error {
    margin-top: 5px;
}

.templateHeader .templateDescription input[readonly="true"] {
    font-size: 20px !important;
    border: 0 none !important;
    padding-left: 0 !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.templateHeader .templateDescription input[ng-reflect-readonly="false"] {
    font-size: 13px !important;
    width: 75%;
}

.templateHeader .mat-form-field.templateRepository {
    width: 23%;
    margin-top: 7px;
    margin-left: 20px !important;
}

.profileInfo .userName {
    color: #ffffff;
}

.profileArrow {
    background: url("./assets/images/profileArrow.png") no-repeat center;
    width: 15px;
    height: 8px;
    float: left;
    cursor: Pointer;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-label {
    color: #30343D;
    opacity: 0.5;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #30343D;
}

.mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    top: 48px;
    left: 5px;
}

.surveyanalyst .mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    top: 21px;
    left: 60px;
    font-family: Ubuntu-Regular;
    font-size: 13px;
}

.mat-form-field.woLabel .mat-form-field-label-wrapper .mat-form-field-label {
    top: 33px;
}

.sharedQuestionTable .mat-radio-inner-circle,
.sharedQuestionTable .mat-radio-outer-circle {
    border: 1px solid #898989 !important;
    height: 10px !important;
    width: 10px !important;
    margin-top: 5px;
}

.searchTextWrap .mat-selection-list span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.primeNewModal .mat-list-item.removeItemBg {
    background: #ffffff !important;
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;
    opacity: 0.2;
}

//ICONS
span.modalClose {
    position: fixed;
    right: 29%;
    margin-top: 30px;
    border-radius: 30px;
    padding-left: 12px;
    padding-top: 10px;
    background: #FFFFFF;
    z-index: 0;
    height: 30px;
    width: 32px;
    outline: 0;
}

span.modalClose i {
    background: url(./assets/images/modal-close.png) no-repeat center;
    width: 16px;
    height: 15px;
    float: left;
    cursor: pointer;
    position: absolute;
    left: 6px;
    top: 7px;
    padding: 6px;
}

.center-modalcloseWrapper {
    position: fixed;
    margin-top: -25px;
    width: 40%;
    float: right;
    text-align: right;
}

.center-modalClose {
    background: url(./assets/images/center-modalClose.png) no-repeat center;
    width: 16px;
    height: 15px;
    float: left;
    cursor: pointer;
    float: right;
    margin-top: -25px;
    margin-right: 8px;
    font-family: Ubuntu-Regular;
    background: #333;
    border-radius: 21px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 2px;
    line-height: 12px;
    position: absolute;
    margin-top: 4px;
    text-align: center;
    margin-right: -22px;
    right: 33px;
}

.infoIcon {
    background: #0089D0;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 0px 5px;
    margin-right: 10px;
    margin-top: 5px;
    float: left;
}

.infoIconBgW {
    background: #ffffff;
    border-radius: 20px;
    color: #0089D0;
    padding: 0px 4px;
    margin-right: 10px;
    margin-top: 3px;
    float: left;
    font-size: 12px;
    border: 1px solid #0089D0;
    width: 6px;
    height: 14px;
}

.deleteIcon {
    background: url("./assets/images/Delete.png") no-repeat center;
    width: 16px;
    height: 16px;
    float: left;
    cursor: pointer;
}

.deleteIcon.float-right {
    float: right !important;
}

.makeCopyIcon {
    background: url("./assets/images/makeAcopy.png") no-repeat center;
    width: 14px;
    height: 19px;
    float: left;
    cursor: pointer;
    margin-right: 10px;
}

.StatusCopyIcon .makeCopyIcon {
    margin-right:40% !important;
}

.inputTitleHeader .inputTitleContent {
    position:relative;
}

.inputTitleContent .mat-icon {
    position: absolute;
    font-size: 15px;
    top: 16px;
    right: 12px !important;
    float: right;
}

.InputInfoContent > p {
    margin: 5px 25px;
    text-align: left;
    float: left;
    font-family : Ubuntu-Regular;
}

.InputInfoContent > p label {
    float:left;
    font-size:14px;
}

.InputInfoContent > p span {
    display: block;
    float: left;
    clear: both;
    font-size:14px;
}

.alignDelete-textbox {
    margin: 7px 0 0 5px;
}

.mat-sort-header-arrow {
    display: none !important;
}

.autocomplete-search {
    background: url("./assets/images/search.png") no-repeat center;
    height: 15px;
    width: 15px;
    float: left;
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: 12px;
}

.sortIcon {
    background: url("./assets/images/sort-icon.png") no-repeat center;
    width: 7px;
    height: 14px;
    float: left;
    cursor: pointer;
}

.saveIcon {
    background: url("./assets/images/save.png") no-repeat center;
    width: 16px;
    height: 16px;
    float: left;
    cursor: pointer;
}

// .sortHighlight {
//     color: #00ff00;
//     width: 15px;
//     height: 15px;
// }
.refreshIcon {
    background: url("./assets/images/refresh.png") no-repeat center;
    width: 15px;
    height: 15px;
    float: left;
    cursor: pointer;
}

.editIcon {
    background: url("./assets/images/edit.png") no-repeat center;
    width: 18px;
    height: 18px;
    float: left;
    cursor: pointer;
}

.viewIcon {
    background: url("./assets/images/viewIcon.png") no-repeat center;
    width: 18px;
    height: 18px;
    float: left;
    cursor: pointer;
}

.isUser {
    width: 100%;
    margin-left: 0;
    margin-right:0;
    padding-left: 0;
    text-align: center;
    border-left: none;
}

.collapseArrow {
    background: url("./assets/images/collapse_arrow.png") no-repeat center;
    width: 16px;
    height: 14px;
    float: left;
    cursor: pointer;
}

.selectTick {
    background: url("./assets/images/select-tick.png") no-repeat center;
    width: 11px;
    height: 11px;
    float: left;
    cursor: pointer;
}

.editBlueIcon {
    background: url("./assets/images/editBlue.png") no-repeat center;
    width: 18px;
    height: 18px;
    float: left;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 3px;
}

.bottomArrow {
    background: url("./assets/images/bottom-arrow.png") no-repeat center;
    position: absolute;
    top: 40px;
    right: 10px;
    width: 19px;
    height: 9px;
}

.inputCloseIcon {
    position: absolute !important;
    top: 16px;
    right: 10px;
    width: 19px !important;
    height: 9px !important;
    font-size: 17px !important;
}

.sharedListinputClose.inputCloseIcon {
    top: -24px;
    right: 8px;
    color: #999 !important;
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
    display: none;
}

.selectArrow-align {
    //top: 26px !important;
    right: 0 !important;
}

.filter-selectAlign {
    top: 16px;
    right: -15px;
}

.align-autosearchIcon {
    top: 37px !important;
}

.mat-select-arrow-wrapper {
    display: none !important;
}

i.bottomArrow[aria-expanded="true"] {
    transform: rotate(180deg);
}

.addnewIcon {
    background: url("./assets/images/addnew.png") no-repeat center;
    height: 25px;
    width: 25px;
    margin: 0 auto;
    float: right;
    margin-left: 10px;
}

.excelIcon {
    background: url("./assets/images/excel.png") no-repeat center;
    height: 24px;
    width: 28px;
    margin: 0 auto;
    float: right;
    margin-left: 25px;
    cursor: pointer;
}
.uploadicon {
    height: 24px;
    width: 28px;
    margin: 0 auto;
    float: right;
    margin-left: 25px;  
}

.icon-wobg {
    color: #007bff!important;
    font-size: 1.2em;
}

.searchicon {
    background: url("./assets/images/search.png") no-repeat center;
    height: 15px;
    width: 15px;
    margin: 0 auto;
    display: block;
}

.searchGrayIcon {
    background: url("./assets/images/search-gray.png") no-repeat center;
    height: 15px;
    width: 15px;
    margin: 0 auto;
    display: block;
    float: right;
}

.quesIcon {
    background: url("./assets/images/question.png") no-repeat center;
    height: 22px;
    width: 22px;
    margin: 0 auto;
    display: block;
}

.templateIcon {
    background: url("./assets/images/template.png") no-repeat center;
    height: 21px;
    width: 21px;
    margin: 0 auto;
    display: block;
}

.choiceIcon {
    background: url("./assets/images/choice.png") no-repeat center;
    height: 19px;
    width: 19px;
    margin: 0 auto;
    display: block;
}

.slistIcon {
    background: url("./assets/images/sharedlist.png") no-repeat center;
    height: 20px;
    width: 18px;
    margin: 0 auto;
    display: block;
}

.isheetIcon {
    background: url("./assets/images/inputsheet.png") no-repeat center;
    height: 19px;
    width: 19px;
    margin: 0 auto;
    display: block;
}

.surveyIcon {
    background: url("./assets/images/survey.png") no-repeat center;
    height: 26px;
    width: 20px;
    margin: 0 auto;
    display: block;
}

//FONTS
@font-face {
    font-family: "Ubuntu-Regular";
    src: url("./assets/font/fonts/Ubuntu-Regular.ttf") format('ttf'), url("./assets/font/fonts/Ubuntu-Regular.woff") format('woff');
}

@font-face {
    font-family: "Ubuntu-Medium";
    src: url("./assets/font/fonts/Ubuntu-Medium.ttf") format('ttf'), url("./assets/font/fonts/Ubuntu-Medium.woff") format('woff');
}

@font-face {
    font-family: "Ubuntu-Bold";
    src: url("./assets/font/fonts/Ubuntu-Bold.ttf") format('ttf'), url("./assets/font/fonts/Ubuntu-Bold.woff") format('woff');
}

body {
    font-family: "Ubuntu-Regular";
    text-align: Center;
    vertical-align: top;
    font-weight: normal;
}

.font-13 {
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
}

.font-11 {
    font-family: "Ubuntu-Regular";
    font-size: 11px;
    line-height: 13px;
}

.font-20 {
    font-family: "Ubuntu-Regular";
    font-size: 20px;
    line-height: 23px;
}

.font-14 {
    font-family: "Ubuntu-Regular";
    font-size: 14px;
    line-height: 16px;
}

.font-18 {
    font-family: "Ubuntu-Regular";
    font-size: 18px;
    line-height: 21px;
}

.font-10 {
    font-family: "Ubuntu-Regular";
    font-size: 10px;
    line-height: 11px;
}

.blue {
    color: #0089D0;
}

.surveyLink {
    font-family: Ubuntu-Regular;
    color:#0089D0;
    cursor: pointer;
  }

//FORMS
.form-control {
    display: block;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group>.form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group-append,
.input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mat-option {
    padding-left: 5px !important;
    font-family: ubuntu-regular;
    font-size: 13px !important;
    line-height: 15px;
    height: 33px !important;
}


/* BUTTONS */

.mat-raised-button.mat-primary,
.mat-raised-button.mat-primary:active {
    background: #0089D0;
    height: 40px;
    width: 46%;
    font-family: Ubuntu-Regular;
    border-radius: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #FFF7F7;
    box-shadow: none;
}

.mat-raised-button.mat-primary:hover {
    background: #0A9FEC;
    box-shadow: none !important;
}

.mat-raised-button.mat-warn {
    width: 46%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #0089D0;
    border-radius: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #0089D0;
    font-family: Ubuntu-Regular;
    box-shadow: none;
    margin: 0 0 0 11px !important;
}

.mat-raised-button.mat-warn:hover,
.mat-raised-button.mat-warn:active {
    background: #E8E8E8;
    box-shadow: none !important;
}


/* HEADER CSS */


/*SEARCH BOX*/

.headerBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 14px 10px;
    background: #000000 !important;
    height: 31px;
}

.nbcuLogo {
    color: #fff;
    text-decoration: none;
    margin-top: 5px;
    float: left;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 10px;
}

.searchBar {
    width: 25%;
    margin: 0 0 0 30px;
    float: left;
}

.bg-dark {
    background-color: #343a40!important;
}

.headerBar .searchBar button,
.headerBar .searchBar button:focus,
.headerBar .searchBar button:active {
    background: #fff;
    border: 0 none !important;
    border-radius: 25px 0 0 25px !important;
    box-shadow: none;
    color: #666;
}

.headerBar .searchBar input,
.headerBar .searchBar input:focus,
.headerBar .searchBar input:active {
    border: 0 none !important;
    border-radius: 0 25px 25px 0 !important;
    box-shadow: none;
    outline: none;
    font-size: 13px !important;
    padding-left: 0 !important;
    font-family: Ubuntu-Regular;
}


/*SIDEBAR*/

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    margin: 59px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background: #0089D0;
    width: 7.33%;
}

.sidebar-sticky {
    position: sticky;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar .sidebar-sticky .nav-item.active,
.sidebar-sticky .nav-item:hover {
    background: #027AB9;
    outline: 0 none;
}

.sidebar .sidebar-sticky li {
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar .sidebar-sticky li a {
    color: #fff !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
    text-decoration: none;
    padding: 0;
}

.sidebar .sidebar-sticky li a p {
    margin: 5px 0 0 0;
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
}


/*CONTENT SECTION*/

.contentSection {
    position: fixed;
    top: 59px;
    left: 7.5%;
    right: 0;
    bottom: 0;
    margin-left: 20px;
}


/*TITLE SECTION*/

.tableTitleHeader {
    margin: 20px 0;
    width: 100%;
    float: left;
}

.createSection {
    margin-right: 20px;
}

.createSection p {
    cursor: pointer;
    color: #0089D0;
    margin-right:10px
}

.createSection p span {
    margin-top: 5px;
    float: left;
    font-family: Ubuntu-Regular;
    margin-bottom: 5px;
}

.mat-bottom-sheet-container {
    //transform: translateX(0%) !important;
    transform: translateX(-30%) !important;
    -webkit-transform: translatex(-30%) !important;
}


/* TABLE */

.question-dashboard-container,
sharedlist-dashboard-container {
    width: 98.2% !important;
    //height:450px;
    //height: calc(100% - 100px);
    overflow-y: auto;
    position: absolute;
    top: 60px;
    bottom: 0;
}

.inputsheet-table-container {
    width: 98.2% !important;
    //height:450px;
    //height: calc(100% - 100px);
    overflow-y: auto;
    position: absolute;
    top: 12%;
    bottom: 0;
}

.promoDyntable {
    overflow-x: auto !important;
    width: 100% !important;
}

.promoDyntable table tr td {
    width:10% !important;
}

.promoDyntable table tr td.promoNumberCol {
    width:5% !important;
}

.promoDyntable table {
    border-collapse: collapse;
}

.promoDyntable table tr {
    height:42px;
}

.promoDyntable tr th {
    background: #30343D;
    padding-top: 15px !important;
    text-transform: uppercase;
    font-weight: normal;
    border-top: 1px solid #D9D9DA;
    font-family: Ubuntu-Medium !important;
}

.promoDyntable tr td, .promoDyntable tr th {
    background: #EDF1F3;
    font-family: "Ubuntu-Regular";
    font-size: 10px;
    line-height: 11px;
    padding-left: 12px !important;
    color:#000;
    text-align: left;
    padding-left: 5px !important;
    border-bottom: 0 none !important;
    border-right: 1px solid #D9D9DA;
    padding-top: 10px;
}

.promoDyntable .promoSetcol {
    max-width:100% !important;
}

.promoDyntable tr td {
    border-top: 0 none !important;
    border-bottom: 1px solid #D9D9DA !important;
}

.mat-elevation-z8 {
    box-shadow: none !important;
    border: 1px solid #D9D9DA;
}

.mat-paginator-sticky {
    bottom: 0px;
    position: sticky;
    z-index: 10;
    background: #30343D;
    color: #FFFFFF;
    right: 0;
    left: 0;
}

.mat-paginator-range-label,
.mat-select-trigger,
.mat-paginator-sticky .mat-icon-button,
.mat-form-field-label-wrapper {
    color: #FFFFFF !important;
}

.mat-paginator-container {
    justify-content: end !important;
    min-height: 46px !important;
}

.mat-paginator-sticky .mat-paginator-container .mat-form-field-infix {
    border-bottom: 1px solid #FFFFFF;
}

table.question-datatable {
    margin: 0 !important;
}

.contentSection .mat-table tr.table-header-section.mat-header-row {
    height: 42px;
}

.contentSection .mat-table .mat-header-cell {
    padding: 0 !important;
    border-bottom: 0 none !important;
    border-right: 1px solid #D9D9DA;
}

.contentSection .mat-table .table-header-section .mat-header-cell {
    background: #30343D;
}

.contentSection .mat-table .table-footer-section {
    background: #30343D;
}

.mat-table .mat-header-cell .sortIcon {
    position: absolute;
    right: 5px;
}

.contentSection .mat-table .mat-header-cell .mat-form-field-infix {
    margin: 0 10px;
}

.mat-header-cell,
.mat-cell {
    //min-width: 100px;
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
}

.mat-column-activeYn {
    width: 7%;
}

.mat-column-action {
    width: 5%;
}

.mat-column-questionPosNegFlag {
    width: 9%;
}

.mat-column-percentileYn {
    width: 8%;
}

.mat-column-surveylink {
    width: 9%;
}

.mat-column-lastUpdatedDt,
.mat-column-createdDt {
    width: 11% !important;
}

.mat-column-summRptYn {
    width: 11%;
}

.mat-column-questionCategoryDesc,
.mat-column-genericText {
    width: 14%;
}

.mat-cell.mat-column-surveylink {
    font-family: Ubuntu-Regular !important;
    font-size: 13px;
    line-height: 15px;
    color: #0089D0 !important;
    cursor: pointer;
}

.mat-cell.mat-column-surveylink:hover {
    opacity: 0.5;
}

.mat-cell.mat-column-surveylink:active {
    color: #981BE4 !important;
}

.mat-table tr.mat-row:nth-child(even) {
    background: #FFFFFF;
}

.mat-table tr.mat-row:nth-child(odd) {
    background: #EDF1F3
}

.mat-table .mat-row {
    height: 40px;
}

.contentSection .mat-table .mat-row .mat-cell {
    border-bottom: 0 !important;
}

.contentSection .mat-table .mat-row .mat-cell {
    padding-left: 10px;
    text-align: left;
    border-right: 1px solid #D9D9DA;
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    padding-right: 5px;
    word-break: break-all;
}

.question-datatable.mat-table .mat-cell .mat-raised-button,
.question-datatable.mat-table .mat-cell .cdk-focused.mat-raised-button:hover,
.question-datatable.mat-table .mat-cell .mat-raised-button:focus,
.question-datatable.mat-table .mat-cell .cdk-focused.mat-raised-button:active {
    background: none;
    box-shadow: none;
}

.contentSection .mat-table .mat-sort-header-container .mat-sort-header-button,
.contentSection .mat-table .mat-header-cell {
    font-family: "Ubuntu-Regular";
    font-size: 10px;
    line-height: 11px;
    padding-left: 12px !important;
    color: #FFFFFF;
    text-align: left;
    padding-left: 5px !important;
}

.table-filter-section.mat-header-row .mat-form-field-underline {
    display: none;
}

.table-filter-section .mat-header-cell .mat-form-field-wrapper {
    padding-bottom: 0;
}

.table-filter-section .mat-header-cell .mat-form-field {
    width: 93%;
    padding: 0 !important;
    border: 0 none !important;
}

.table-filter-section .mat-header-cell .mat-form-field .mat-form-field-infix input.mat-input-element,
.table-filter-section .mat-header-cell .mat-form-field .mat-form-field-infix mat-select.mat-select {
    margin-top: 2px;
    width: 90% !important;
    padding-left: 0 !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px !important;
    line-height: 15px;
    height: 16px;
    color: #000000;
}

.table-filter-section .mat-header-cell .mat-form-field .mat-form-field-infix {
    width: 100%;
    height: 15px;
    margin-left: 0;
    padding-left: 0;
}

.table-filter-section .mat-header-cell .mat-form-field .mat-form-field-wrapper .mat-form-field-outline-thick {
    background: #EEEEEE;
    border: 1px solid #d0d0d0;
    color: transparent;
    border-radius: 5px;
}

.mat-form-field .mat-form-field-infix .mat-select-trigger {
    width: 100%;
}

.table-filter-section .mat-header-cell .mat-form-field .mat-form-field-infix .mat-select-trigger .mat-select-value {
    color: #000000;
    font-size: 13px;
    font-family: "Ubuntu-Regular";
    line-height: 15px;
}

.contentSection table.mat-table {
    border-collapse: collapse;
    width: 100%;
}

.contentSection .mat-table tr.mat-row:last-child {
    border-bottom: 1px solid #D9D9DA;
}

.contentSection .mat-table .table-footer-section {
    height: 46px;
}

.contentSection .mat-table .table-footer-section .mat-footer-cell {
    color: #FFFFFF;
    font-family: Ubuntu-Regular;
    font-size: 13px;
    line-height: 15px;
    padding-left: 10px;
    text-align: left;
}


/* CREATE QUESTION */

.primeNewModal .mat-card {
    box-shadow: none !important;
    padding: 0;
}

.primeNewModal .mat-form-field {
    width: 100%;
}

.newQuestion .primeNewModal .mat-card-content {
    position: absolute;
    right: -19px;
    left: 0;
    bottom: 94px;
    top: 0;
    overflow-y: auto;
    padding-right: 17px;
    padding-bottom: 10px;
    overflow-x: hidden;
}

.mat-form-field .mat-form-field-underline {
    display: none;
}

// .primeNewModal .mat-form-field .mat-form-field-label-wrapper {
//     display:none;
// }
// .primeNewModal .mat-form-field-label mat-label {
//     display:none;
// }
.primeNewModal .mat-form-field label {
    font-family: Ubuntu-Medium;
}

.primeNewModal .mat-card-title {
    margin: 0;
}

.primeNewModal input.mat-input-element,
.primeNewModal mat-select.mat-select,
.primeNewModal textarea {
    margin-top: 7px;
    width: 94.5% !important;
    padding-left: 5px !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px !important;
    line-height: 15px;
    height: 18px;
    border: 1px solid #D0D0D0;
}

.primeNewModal input.mat-input-element:hover,
.primeNewModal mat-select.mat-select:hover,
.primeNewModal textarea:hover {
    border: 1px solid #D0D0D0;
    // background:#EEEEEE;
}

.primeNewModal input.mat-input-element:active,
.primeNewModal mat-select.mat-select:active,
.primeNewModal textarea:active {
    border: 1px solid #5F5858;
    // background:#EEEEEE;
}

.primeNewModal textarea {
    resize: none !important;
    margin-top: 5px !important;
    height: 30px;
}

.mat-form-field-subscript-wrapper {
    margin-top: 0 !important;
}

.primeNewModal .connotation-container {
    margin-bottom: 15px;
    float: left;
}

.primeNewModal .connotation-container label {
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 5px;
}

.primeNewModal .mat-radio-group {
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 5px;
}

.primeNewModal .mat-radio-group .mat-radio-button {
    margin-right: 7%;
}

.primeNewModal .mat-radio-group .mat-radio-button:first-child .mat-radio-label-content {
    color: #187C2E;
}

.primeNewModal .mat-radio-group .mat-radio-button:nth-child(2) .mat-radio-label-content {
    color: #CE0B0B;
}

.primeNewModal .mat-radio-group .mat-radio-button .mat-radio-label-content {
    font-family: "Ubuntu-Medium";
    font-size: 13px;
    line-height: 15px;
    text-transform: lowercase;
}

.primeNewModal .mat-radio-group .mat-radio-button .mat-radio-label-content::first-letter {
    text-transform: uppercase;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background: #2387C8;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
    border: 1px solid #2387C8;
}

.primeNewModal .slider-container {
    clear: both;
    width: 100%;
    float: left;
    margin-bottom: 10px;
}

.primeNewModal .slider-container.activeToggle {
    border-bottom: 1px solid #D9D9DA;
    padding-bottom: 15px;
}

.primeNewModal .slider-container label {
    float: left;
    font-family: Ubuntu-Medium;
    font-size: 13px;
    line-height: 15px;
}

.primeNewModal .slider-container .mat-slide-toggle {
    float: right;
}

.primeNewModal .slider-container .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: #0089D0;
}

.primeNewModal .slider-container .mat-slide-toggle .mat-slide-toggle-bar {
    background: #909395;
    height: 25px;
    width: 90px;
    border-radius: 15px;
}

.primeNewModal .slider-container .mat-slide-toggle .mat-slide-toggle-content {
    position: absolute;
    width: 90px;
    float: left;
    height: 25px;
    color: #ffffff;
}

.primeNewModal .slider-container .mat-slide-toggle .toggleNo {
    position: absolute;
    right: 10px;
    top: 5px;
    display: block;
    font-family: Ubuntu-Regular;
}

.primeNewModal .slider-container .mat-slide-toggle .toggleYes {
    position: absolute;
    left: 10px;
    top: 5px;
    display: none;
    font-family: Ubuntu-Regular;
}

.percentileToggle .mat-slide-toggle.mat-checked .toggleYes {
    display: block;
}

.percentileToggle .mat-slide-toggle.mat-checked .toggleNo {
    display: none;
}

.activeToggle .mat-slide-toggle.mat-checked .toggleYes {
    display: block;
}

.activeToggle .mat-slide-toggle.mat-checked .toggleNo {
    display: none;
}

.summaryToggle .mat-slide-toggle.mat-checked .toggleYes {
    display: block;
}

.summaryToggle .mat-slide-toggle.mat-checked .toggleNo {
    display: none;
}

.posnegToggle .mat-slide-toggle.mat-checked .toggleYes {
    display: block;
}

.posnegToggle .mat-slide-toggle.mat-checked .toggleNo {
    display: none;
}

.activeYnToggle .mat-slide-toggle.mat-checked .toggleYes {
    display: block;
}

.activeYnToggle .mat-slide-toggle.mat-checked .toggleNo {
    display: none;
}

.primeNewModal .slider-container .mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: #FFFFFF;
}

.primeNewModal .slider-container .mat-slide-toggle .mat-slide-toggle-thumb-container {
    left: 3px;
    top: 2px;
}

.primeNewModal .slider-container .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    right: 19px;
    left: unset !important;
    top: 2px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.newQuestion .mat-dialog-container {
    right: 0;
    top: 60px;
    height: 100%;
    position: absolute !important;
    width: 31%;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.surveyModal {
    width: 40%;
    margin-top: 50px;
    padding-top: 30px;
    background: #ffffff;
    position: absolute !important;
    top: 75px !important;
}

.infoIcon {
    background: #0089D0;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 1px 5px;
    margin-right: 10px;
    margin-top: 3px;
    float: left;
    font-size: 12px;
}

.cdk-overlay-pane.surveyModal .mat-dialog-container {
    padding: 0 0 0 0 !important;
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
}

.cdk-overlay-pane.surveyModal .mat-dialog-container td.mat-cell {
    text-align: left;
}

.cdk-overlay-pane.surveyModal .mat-column-surveyId,
.cdk-overlay-pane.surveyModal .mat-column-surveyCode,
.cdk-overlay-pane.surveyModal .mat-column-createdDt {
    width: 17%;
}

.surveyModal .mat-header-row {
    height: 42px !important;
    background: #30343D;
}

.surveyModal .mat-header-row .mat-header-cell {
    color: #FFFFFF;
    font-size: 13px;
    line-height: 15px;
    font-family: Ubuntu-Regular;
    position: relative;
    border-bottom: 0 none !important;
    border-right: 1px solid #D9D9DA;
    padding-left: 15px;
    cursor: pointer;
}

.surveyModal .mat-row .mat-cell {
    padding-left: 15px;
    text-align: left;
    border-right: 1px solid #D9D9DA;
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    padding-right: 15px;
}

.cdk-overlay-container .cdk-overlay-pane .mat-dialog-container {
    border-radius: 0;
    padding: 10px 20px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-snack-bar-container {
    margin-top: 64px;
    box-shadow: none;
    background: #38ABF3;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-snack-bar-container .mat-simple-snackbar {
    display: block !important;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-snack-bar-container.success {
    background: #0BB04A;
    border-radius: 5px;
    color: #FFFFFF;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-snack-bar-container.warn {
    background: #CE0B0B;
    color: #FFFFFF;
}

.primeNewModal .mat-card .twoCol .mat-form-field {
    width: 90%;
    float: left;
}

.mat-card-actions .mat-raised-button.mat-primary {
    margin: 0 !important;
}

.mat-dialog-container .primeNewModal .mat-card-actions {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 45px;
    background: #ffffff;
    padding: 9px 15px 15px 15px;
    border-top: 1px solid #ccc;
    margin: 0 -20px;
}

.mat-dialog-container .primeNewModal,
.mat-dialog-container .mat-card {
    height: 100%;
}


/*SLIDER MODAL*/

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 35%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.right .modal-body {
    padding: 15px 15px 80px;
}


/*Right*/

.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}


/* ----- MODAL STYLE ----- */

.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}


/*SCROLL BAR CSS*/


/* width */

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* STATUS MESSAGE */

.statusMsg {
    margin: 0 auto;
    text-align: left;
    width: 35%;
    background: #0BB04A;
    border-radius: 5px;
    padding: 12px 20px;
    position: absolute;
    top: 64px;
    color: #ffffff;
    left: 30%;
}


/* CHOICE */

.mat-column-choiceLibraryDesc {
    width: 30%;
}

.mat-column-createdBy {
    width: 15%;
}

.mat-column-posNegFlag,
.mat-column-activeYn,
.mat-column-createdDt,
.mat-column-lastUpdatedDt,
.mat-column-surveylink {
    width: 10%;
}

.mat-column-action {
    width: 5%;
}


/*SHARED LIST*/

.shared-list-sidenav .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
}

.shared-list-sidenav .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.shared-list-sidenav .mat-form-field .mat-form-field-label {
    padding-left: 10px;
    font-family: Ubuntu-Regular;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    width: 80%;
    padding-top: 5px;
}

.shared-list-sidenav .mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    top: 18px;
    left: 0px;
    font-weight: bold;
}

.shared-list-sidenav .mat-list-item[aria-selected="true"] {
    /*background:#EBEBEC;*/
}

.shared-list-sidenav .mat-list-text .mat-list-item {
    font-family: Ubuntu-Regular;
    font-size: 14px !important;
    line-height: 15px;
    color: #000000;
}

.shared-list-sidenav .mat-list-text .mat-list-item .mat-list-item-content span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shared-list-sidenav .mat-list-option {
    height: 30px !important;
}

.shared-list-sidenav .mat-list-item .mat-list-item-content {
    padding: 0 0 0 5px !important;
    text-align: left;
}


/*CREATE SHARED CHOICE */

.primeNewModal .mat-list-item[aria-selected="true"] {
    background: #EBEBEC;
}

.templateSelQuestion.primeNewModal .mat-list-item[aria-selected="true"] {
    background: #FFFFFF !important;
}

.templateSelQuestion.primeNewModal .mat-list-item.createQuesselected[aria-selected="true"] {
    background: #EBEBEC !important;
}

.primeNewModal .mat-list-text .mat-list-item {
    font-family: Ubuntu-Regular;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
}

.primeNewModal .mat-list-text .mat-list-item .mat-list-item-content span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.primeNewModal .mat-list-option {
    height: 30px !important;
}

.primeNewModal .mat-list-item .mat-list-item-content {
    padding: 0 0 0 5px !important;
    text-align: left;
}

.primeNewModal.sharedQues-QuesView .sharedListDescription,
.primeNewModal.sharedQues-QuesView .sharedListName {
    font-size: 14px !important;
    font-family: Ubuntu-Regular;
    line-height: 17px;
}


/* SHARED TABLE */

.sharedQues-QuesView .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.sharedQuestions {
    width: 100%;
}

.sharedQuestionTable {
    width: 96%;
    overflow: auto;
}

.sharedQuestionTable.simpleQues {
    width: 97%;
    overflow-x: hidden;
}

.editOnly .sharedQuestionTable {
    top: 170px;
}

.editOnly .sharedQuestionTable .punchCodeList {
    margin-bottom: 10px;
}

.editOnly .sharedQuestionTable input.mat-input-element.punchCodeList {
    width: 10px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
}

.editOnly .sharedQuestionTable .deleteIcon {
    margin-left: 4px;
}

.editOnly .sharedQuestionTable th:first-child .deleteIcon {
    margin-left: 0;
}

.sharedQuestionTable .mat-table .mat-cell,
.sharedQuestionTable .mat-table .mat-header-cell {
    min-width: 180px;
}

.editOnly .sharedQuestionTable .mat-table .mat-cell:first-child,
.editOnly .sharedQuestionTable .mat-table .mat-header-cell:first-child {
    min-width: 50px;
}

.sharedQuestionTable .mat-table .mat-header-cell {
    font-weight: bold;
}

.sharedQuestionTable .mat-table .mat-header-cell.mat-column-action {
    min-width: 50px;
    width: 50px;
}

.sharedQuestionTable .mat-header-row .mat-header-cell {
    background: #EDF1F3 !important;
    color: #000000;
    font-family: Ubuntu-Regular;
    font-size: 13px;
    line-height: 15px;
    padding-left: 10px !important;
}

.sharedQuestionTable .mat-table,
.sharedQuestionTable .mat-table .mat-cell,
.sharedQuestionTable .mat-table .mat-header-cell {
    border: 0 none !important;
}

.sharedQuestionTable .mat-table tr.mat-row:nth-child(odd) {
    background: #FFFFFF;
}

.sharedQuestionTable .mat-table tr.mat-row:nth-child(even) {
    background: #EDF1F3;
}

.sharedQuestionTable .mat-table tr.mat-row:last-child {
    border-bottom: 0 none;
}

.sharedQuestionTable table.mat-table {
    border-collapse: unset;
}

.sharedQues-QuesView .mat-form-field-type-mat-input .mat-form-field-infix {
    padding: 0 !important;
}

.sharedQues-QuesView .sharedQuestionTable .addChoice {
    margin-right: 10px;
    //position: fixed;
    //right:2%;
    margin-top: 1px;
    cursor: pointer;
}

.sharedQues-QuesView .sharedQuestionTable .addChoice i {
    margin-right: 10px;
    float: left !important;
}

.sharedQues-QuesView .sharedQuestionTable .addQuestion {
    margin-bottom: 0;
    cursor: pointer;
}

.sharedQues-QuesView .sharedQuestionTable .addQuestion i {
    margin: 0 10px 0 0;
    float: left;
}

.sharedQuestionTable table tr td:first-child {
    display: none;
}

.sharedQuestionTable table tr th:first-child {
    display: none;
}

.editOnly .sharedQuestionTable table tr td:first-child {
    display: table-cell;
}

.editOnly .sharedQuestionTable table tr th:first-child {
    display: table-cell;
}

.input-searchWrapper {
    position: relative !important;
    float: left;
    width: 100%;
}

.input-searchWrapper .searchicon {
    top: auto !important;
    bottom: 9px !important;
}

.selection-placeholder .mat-form-field-label-wrapper .mat-form-field-label {
    top: auto !important;
    bottom: 155px !important;
}


/*SHARED ADD QUESTION MODAL */

::ng-deep.mat-pseudo-checkbox {
    display: none !important;
}

.primeNewModal .mat-selection-list {
    /*height:21vh;*/
    height: 130px;
    overflow-y: scroll;
    padding: 0 !important;
    margin-top: 0;
    outline: none;
    width: 99%;
    float: left;
    clear: both;
}

.primeNewModal .mat-form-field .searchicon {
    position: absolute;
    right: 0;
    top: 20px;
    right: 10px;
}


/*SHARED CHOICE */

.createsharedChoice .autoSelect+.mat-selection-list {
    margin-top: 0 !important;
}

.createsharedChoice .autoSelect .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}


/* CONFIRM MODAL */

.confirmModal {
    width: 26%;
    vertical-align: middle;
    display: flex;
    align-content: center;
    justify-content: center;
}

.cdk-overlay-pane.confirmModal .mat-dialog-container {
    padding: 30px 20px;
}

.confirmModal p {
    font-family: Ubuntu-Regular;
    font-size: 14px;
    line-height: 16px;
    color: #30343D;
    margin-bottom: 20px;
}

.confirmModal button {
    width: 34% !important;
}

.confirmModal button.mat-warn {
    margin-left: 15% !important;
}

.spinner {
    //padding-top: 17rem;
    height: 18px;
    text-align: center;
    position: absolute;
    left: 43%;
    top: 50%;
    z-index: 99999;
    >div {
        width: 18px;
        height: 18px;
        margin: 0 .1rem;
        background-color: #158BCE !important;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        &.bounceone {
            -webkit-animation-delay: -0.60s;
            animation-delay: -0.60s;
            background-color: #fcb711;
        }
        &.bouncetwo {
            -webkit-animation-delay: -0.50s;
            animation-delay: -0.50s;
            background-color: #f37021;
        }
        &.bouncethree {
            -webkit-animation-delay: -0.40s;
            animation-delay: -0.40s;
            background-color: #cc004c;
        }
        &.bouncefour {
            -webkit-animation-delay: -0.30s;
            animation-delay: -0.30s;
            background-color: #6460aa;
        }
        &.bouncefive {
            -webkit-animation-delay: -0.20s;
            animation-delay: -0.20s;
            background-color: #0089d0;
        }
        &.bouncesix {
            -webkit-animation-delay: -0.10s;
            animation-delay: -0.10s;
            background-color: #0db14b;
        }
    }
}

.spinnerdialogBox {
    padding-top: 7rem;
    height: 10px;
    text-align: center;
    min-height: 12rem;
    >div {
        width: 16px;
        height: 16px;
        margin: 0 .1rem;
        background-color: #158BCE !important;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        &.bounceone {
            -webkit-animation-delay: -0.60s;
            animation-delay: -0.60s;
            background-color: #fcb711;
        }
        &.bouncetwo {
            -webkit-animation-delay: -0.50s;
            animation-delay: -0.50s;
            background-color: #f37021;
        }
        &.bouncethree {
            -webkit-animation-delay: -0.40s;
            animation-delay: -0.40s;
            background-color: #cc004c;
        }
        &.bouncefour {
            -webkit-animation-delay: -0.30s;
            animation-delay: -0.30s;
            background-color: #6460aa;
        }
        &.bouncefive {
            -webkit-animation-delay: -0.20s;
            animation-delay: -0.20s;
            background-color: #0089d0;
        }
        &.bouncesix {
            -webkit-animation-delay: -0.10s;
            animation-delay: -0.10s;
            background-color: #0db14b;
        }
    }
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}


/* Survey */

.surveySteps .mat-step-header {
    width: 20px;
    padding: 0;
    height: 20px;
}

.surveySteps .mat-horizontal-stepper-header-container {
    width: 20%;
    margin: 0 auto;
}

.surveySteps .mat-stepper-horizontal-line {
    border: 1px solid #EDEDED;
}

.surveySteps .mat-step-icon-content {
    display: none;
}

.surveySteps .surveyDetailsFooter {
    clear: both;
    overflow-y: auto;
    position: absolute;
    bottom: 10px;
    right: 20px;
    left: 20px;
}

.surveySteps .surveyDetailsFooter button {
    float: right;
}

.surveySteps .surveyDetailsFooter button.float-left {
    float: left !important;
}

.surveySteps .surveyDetailsFooter .mat-button-wrapper {
    font-family: "Ubuntu-Regular";
    font-size: 13px;
    line-height: 15px;
    color: #0089D0;
}

.surveySteps .mat-step-header .mat-step-icon-selected,
.surveySteps .mat-step-header .mat-step-icon-state-done,
.surveySteps .mat-step-header .mat-step-icon-state-edit {
    background: #0A9FEC;
}

.surveySteps .mat-step-header .mat-step-icon {
    height: 20px;
    width: 20px;
}

.summarySection-box .mat-expansion-panel-content .titleInput {
    width: 30%;
    float: left;
    margin: 0 0 15px 0;
    height: 17px;
    font-family: Ubuntu-Regular;
    font-size: 13px !important;
}

.surveyAddChoice {
    margin: 15px 20px 0 0 !important;
}

.surveyDetailsWrapper.summaryStep .summarySection-box .mat-table tr.mat-row td {
    border-right: 1px solid #D9D9DA !important;
}

.surveyDetailsWrapper.summaryStep .summarySection-box .mat-table tr.mat-row td input {
    width: 30% !important;
    float: left;
    padding-left: 5px;
    font-family: Ubuntu-Regular;
    font-size: 14px !important;
    height: 29px;
}

.submitSurvey {
    padding: 13px !important;
}


/* TEMPLATE CSS */


/* TEMPLATE SIDENAV */

.expandPanel .templateSideNav {
    width: 34% !important;
    overflow: hidden !important;
}

.expandPanel .templateSideNav .mat-tab-header {
    padding: 9px 0 5px 0;
}

.templateSideNav .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
}

.templateSideNav .mat-tab-group .mat-tab-label-container .mat-tab-label {
    min-width: 50%;
    opacity: 1;
    padding: 6px 15px 9px;
    height: auto !important;
}

.templateSideNav .mat-tab-group .mat-tab-label-container .mat-tab-list {
    width: 100%;
}

.templateSideNav .mat-tab-group .mat-tab-label-container .mat-tab-label .mat-tab-label-content {
    white-space: normal;
    font-size: 14px;
    font-family: Ubuntu-Regular;
    line-height: 14px;
    color: #000000;
    padding: 8px 0;
}

.templateSideNav .mat-tab-group .mat-tab-label-container .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
    font-weight: 600;
    border-bottom: 2px solid #0089D0;
}

.templateSideNav .mat-tab-group .mat-tab-label-container .mat-ink-bar {
    display: none;
}

.templateSideNav .example-handle {
    display: none;
}

.templateSideNav .mat-menu-trigger {
    display: none;
}

.templateSideNav .mat-tab-body .mat-tab-body-content {
    overflow-x: hidden;
}

.templateSideNav .mat-tab-body .example-list {
    border-radius: 0;
    border: 0 none;
}

.expandPanel.templateSideNav .mat-tab-body .mat-tab-body-content .col-Mat {
    border-radius: 0;
    border: 0 none;
    width: 42%;
    margin-right: 6%;
    float: left;
}

.expandPanel.templateSideNav {
    position: relative;
    width: 99%;
    text-align: left;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    vertical-align: middle;
}

.expandPanel.templateSideNav .mat-tab-body .mat-tab-body-content {
    overflow-x: auto;
    display: flex;
}

.expandPanel.templateSideNav .col-Mat {
    display: inline-block;
    width: 14em;
    margin: 0.5em;
    white-space: normal;
}

.templateSideNav .mat-tab-body .example-list {
    border-radius: 0;
    margin-top: 5px;
}

.templateSideNav .mat-tab-body .example-list .example-box {
    text-align: left;
    padding: 0;
    border: 0 none !important;
    box-shadow: none;
    font-family: Ubuntu-Regular;
    font-size: 14px;
    height: 30px;
    border-radius: 0;
    float: left;
    width: 100%;
    text-align: left;
    display: block;
    padding-left: 10px;
    padding-top: 6px;
}

.templateSideNav .mat-tab-body-wrapper {
    height: 100%;
}

.templateSideNav .mat-tab-body .example-list .example-box span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 84%;
    float: left;
}

.templateSideNav .mat-tab-body .example-list .example-box.createQuesselected>span {
    width: 75%;
}

.templateSideNav .mat-tab-body .example-list .example-box.createQuesselected .mat-menu-trigger {
    display: block;
    position: absolute;
    right: 2px;
    padding: 0 !important;
    min-width: 23px;
    top: -6px;
    line-height: 25px;
}

.templateSideNav .mat-tab-body .example-list .example-box.createQuesselected .mat-menu-trigger .mat-button-wrapper {
    font-size: 30px;
    font-family: Ubuntu-Regular;
    width: 100%;
}

.templateSideNav .example-list .example-box.dot::after {
    background: #F0B6B6;
    width: 5px;
    height: 13px;
    content: "";
    float: left;
    position: absolute;
    left: 2px;
    top: 9px;
}

.templateSaveAs {
    padding-top: 0 !important;
}

.templateSaveAs .mat-form-field {
    width: 90%;
    height: 85px;
}

.templateSaveAs .saveAsAction {
    clear: both;
}

.templateSaveAs .saveAsAction .mat-primary {
    float: right;
    margin-left: 0 !important;
}

.templateSaveAs .saveAsAction button {
    float: right;
    margin-left: 5% !important;
}

.templateSaveAs .mat-form-field input {
    width: 100%;
    margin: 5px 0 !important;
    font-size: 14px !important;
    font-family: Ubuntu-Regular;
    padding-left: 5px !important;
}

.templateMenuDropdown.mat-menu-panel .mat-menu-item {
    height: 30px;
    line-height: 10px;
}

.templateMenuDropdown.mat-menu-panel .mat-menu-content {
    padding: 6px 0 6px 0 !important;
}

.templateSideNav .createSection {
    position: absolute;
    bottom: 10px;
    margin-right: 0;
    width: 100%;
    margin-left: 10px;
}

// .templateSideNav .showMoreSection {
//     position: absolute;
//     bottom: 50px;
//     margin-right: 0;
//     width: 100%;
//     margin-left:20px;
// }
// .templateSideNav .showMoreSection p {
//     cursor: pointer;
// }
.templateSideNav .shared-list-tap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.templateSideNav .mat-tab-group {
    position: absolute;
    top: 0;
    bottom: 75px;
    width: 100%;
}

.expandPanel .templateSideNav .mat-tab-group .mat-tab-label-container .mat-tab-label {
    min-width: 30% !important;
}


/* TEMPLATE CONTENTSECTION */

.contentSection.expandPanel {
    left: 41.4% !important;
}


/* INPUT SHEET */

.inputsheet-table .mat-column-action {
    padding: 2px 0 0 10px !important;
}

.pilotMain .inputSheet-table-create .mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    top: 28px !important;
    left: 5px !important;
}

.pilotMain .mat-accordion table .mat-header-row .mat-header-cell,
.pilotMain .mat-accordion table .mat-header-row .mat-header-cell .mat-checkbox-label {
    font-family: Ubuntu-Medium !important;
    font-weight: normal !important;
}

.pilotMain .mat-accordion .mat-cell .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.pilotMain .mat-accordion .mat-cell .mat-form-field-wrapper .mat-select {
    margin-top: 2px;
    width: 90% !important;
    padding-left: 5px !important;
    font-family: "Ubuntu-Regular";
    font-size: 13px !important;
    line-height: 15px;
    height: 16px;
    color: #000000;
}

.pilotMain .mat-column-select .mat-checkbox {
    margin: 0 5px !important;
}

.pilotMain .mat-accordion table td.mat-column-gender .mat-radio-label {
    margin-right: 15px;
}

.pilotMain .mat-accordion table td.mat-column-gender .mat-radio-label-content {
    padding-left: 5px;
}

.pilotMain .mat-accordion .mat-expansion-panel-body {
    padding: 0 !important;
}

.programmerNotes.mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
    font-size: 13px !important;
}

.programmerNotes {
    margin-bottom: 20px;
    width:38%;
}

.noRecords {
    font-family: Ubuntu-Medium;
    margin: 20px;
    font-size: 20px;
    text-align: center;
}
.noResultsMessage{
    font-size:13px !important;
}

.programmerNotes .mat-form-field {
    width:100%;
}

.programmerNotes label {
    width: auto;
    margin-top: 6px;
    text-align: left;
    margin-right: 10px;
}

.programmerNotes input, .programmerNotes textarea {
    float: left;
    width: 60%;
    font-family: Ubuntu-Regular;
    font-size: 13px !important;
    text-align: left;
    padding-left: 5px !important;
}

.programmerNotes .mat-form-field-infix {
    width: auto !important;
}

.pilotMain .mat-expansion-panel-header {
    height: 20px !important;
    padding: 10px 24px;
}

.colGender.mat-column-gender {
    width: 22% !important;
}

.mat-expansion-panel-header-title {
    font-family: Ubuntu-Regular;
    font-size: 18px !important;
    text-align: left;
}

span.expansion_header_title {
    position: absolute;
    top: -40px;
    left: 0;
    width: 150px;
    background: #0089d0;
    padding: 11px 0 11px 24px;
    border-radius: 10px 10px 0px 0px;
    color: #FFFFFF;
    font-family: Ubuntu-Regular;
    font-size: 13px !important;
}

.exp-toggle span {
    cursor: pointer;
    color: #0089D0;
    font-size: 15px;
    font-family: Ubuntu-Regular;
}

.generateData button {
    width: 100px;
}

.generateData span {
    margin: 11px 10px 0 0;
}

.addInputSheetRows button {
    float: left;
    padding-left: 5px;
}

.addInputSheetRows .addnewIcon {
    margin-top: 5px;
    margin-left: 5px;
}

/* @import '../node_modules/saturn-datepicker/bundle.css'; */
.material-icons {
    cursor: pointer;
}
mat-date-range-input {
    color: #000000 !important;
}

@media screen and (min-width: 1600px) {
    .testDate mat-datepicker-toggle {
        right: 22% !important;
    }

    .templateHeader .mat-form-field.templateRepository {
        width: 27%;
    }

    .question-dashboard-container,
    sharedlist-dashboard-container {
        width: 98.7% !important;
    }
    span.modalClose {
        right: 30% !important;
    }
    .dashboardData .dashboardLegend {
        margin-top: 3.6% !important;
        margin-left: -15% !important;
    }
    .dashboardChart {
        margin-left: 26% !important;
    }
    .primeNewModal input.mat-input-element,
    .primeNewModal mat-select.mat-select,
    .primeNewModal textarea {
        width: 96.5% !important;
    }
    .templateHeader .mat-form-field .mat-form-field-infix mat-select.mat-select,
    .InputSheetHeader .mat-form-field .mat-form-field-infix mat-select.mat-select {
        width: 97% !important;
    }
}

@media screen and (min-width: 1400px) {
    .testDate mat-datepicker-toggle {
        right: 17%;
    }
}

@media screen and (min-width: 1700px) {
    .testDate mat-datepicker-toggle {
        right: 25% !important;
    }
}

